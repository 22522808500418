<template>
  <div class="container">
    <img class="notfound" src="@/assets/images/404.png" />
    <p class="tips">请在高效执行APP访问该页面</p>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .notfound {
    width: 50%;
    margin-top: 40%;
    margin-bottom: 20px;
  }
  .tips {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>